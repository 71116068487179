// eslint-disable-next-line import/no-anonymous-default-export
export default {

    
    baseUrl:"https://reporttestor.pythonanywhere.com",
    imgUrl:"https://reporttestor.pythonanywhere.com",

    BaseUrlVariable:"https://reporttestor.pythonanywhere.com/subadmin",

    UserBaseUrl: "https://reporttestor.pythonanywhere.com/user"
    
    
    
  

}
